<template>
    <div class="programme">
        <img src="../assets/img/bg-btn-programme.svg" class="programme__bg" alt="" />
        <button @click="openProgramPdf" class="programme__btn-programme btn btn-orange px-4 py-2--5 fs-3">
            VOIR LE PROGRAMME
        </button>
    </div>
</template>

<script>
export default {
    methods: {
        openProgramPdf() {
            window.open("/resources/PROGRAMME LADYj.Tech 3.pdf", "_blank");
        },
    },
};
</script>
