<template>
    <div class="inscription position-relative d-flex-center">
        <img src="../assets/img/bg-form.svg" class="inscription__bg" alt="" />

        <img src="../assets/img/logo-metro.svg" class="inscription__logo-metro" alt="" />

        <div class="container-fluid inscription__form mx-auto d-flex-center flex-column my-4">
            <div v-if="isLoading" class="loader d-flex-center flex-column">
                <div class="d-flex-center flex-column bg-black bg-opacity-50 rounded p-3">
                    <div class="spinner-border text-green-light" role="status"></div>
                    <strong class="text-green-light mt-3">Envoi du formulaire en cours...</strong>
                </div>
            </div>
             

            <div
                v-if="hasError"
                class="w-100 alert alert-danger text-white d-flex align-items-center rounded-1"
                role="alert"
            >
                <i class="fa-solid fa-times me-3"></i>
                <div>Une erreur est survenue lors de l'envoi du formulaire, veuillez réessayer ultérieurement.</div>
            </div>

            <img src="../assets/img/logo-2.png" class="inscription__logo mb-4" alt="" />

            <div class="bg-white p-3 shadow rounded" v-if="!isClosed">
                <h2 class="w-100 bg-green-dark text-white rounded mb-3 px-1--5 py-3">
                    FORMULAIRE D'EXPRESSION DE VOTRE BESOIN
                </h2>

                <form method="POST" class="needs-validation" novalidate>
                    <div class="row g-3">
                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form_data.company_name"
                                    id="company_name"
                                    name="company_name"
                                    placeholder="SAS COMPANY"
                                    required
                                />
                                <label for="company_name">Entreprise ou Organisme*</label>
                                <div class="invalid-feedback">Veuillez renseigner votre société</div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form_data.company_domain"
                                    id="company_domain"
                                    name="company_domain"
                                    placeholder="Médicale"
                                    required
                                />
                                <label for="company_domain">Secteurs(s) industriel(s) / filière(s)*</label>
                                <div class="invalid-feedback">Veuillez renseigner votre filière</div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-7">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form_data.thematique"
                                    id="thematique"
                                    name="thematique"
                                    placeholder="Thématique"
                                    required
                                />
                                <label for="thematique">Thématique et/ou verrou(s) technologique à lever*</label>
                                <div class="invalid-feedback">Veuillez renseigner une thématique</div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-5">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form_data.keywords"
                                    id="keywords"
                                    name="keywords"
                                    placeholder="Mot(s) clé(s)"
                                    required
                                />
                                <label for="keywords">Mots-clés*</label>
                                <div class="invalid-feedback">Veuillez renseigner des mots-clés</div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="form-floating">
                                <textarea
                                    class="form-control"
                                    placeholder="Votre description"
                                    v-model="form_data.description"
                                    id="description"
                                    name="description"
                                    style="height: 100px"
                                    required
                                ></textarea>
                                <label for="description">
                                    Description (contexte, objectifs industriels, échéances)*
                                </label>
                                <div class="invalid-feedback">Veuillez saisir une description</div>
                            </div>
                        </div>

                        <div class="col-12 bg-green-dark" style="height: 1px"></div>

                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="text"
                                    v-model="form_data.full_name"
                                    id="full_name"
                                    name="full_name"
                                    placeholder="Alain Dupond"
                                    required
                                />
                                <label for="full_name">Nom Prénom*</label>
                                <div class="invalid-feedback">Veuillez renseigner votre nom</div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="form_data.company_role"
                                    id="company_role"
                                    name="company_role"
                                    placeholder="SAS COMPANY"
                                    required
                                />
                                <label for="company_role">Fonction(s)*</label>
                                <div class="invalid-feedback">Veuillez renseigner vos fonctions</div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="email"
                                    v-model="form_data.email"
                                    id="email_address"
                                    name="email_address"
                                    placeholder="nom@exemple.fr"
                                    required
                                />
                                <label for="email_address">Adresse email*</label>
                                <div class="invalid-feedback">Adresse email invalide</div>
                            </div>
                        </div>

                        <div class="col-12 col-sm-6">
                            <div class="form-floating">
                                <input
                                    class="form-control"
                                    type="tel"
                                    v-model="form_data.phone_number"
                                    id="phone_number"
                                    name="phone_number"
                                    placeholder="0701020304"
                                    required
                                />
                                <label for="phone_number">Numéro de téléphone*</label>
                                <div class="invalid-feedback">Veuillez renseigner votre numéro de téléphone</div>
                            </div>
                        </div>
                    </div>

                    <button @click="saveData" class="w-100 btn btn-green-dark btn-lg mt-3" type="button">
                        Valider
                    </button>

                    <div class="mt-3 fs-6 text-center">
                        <a
                            href="mailto:contact@sayens.fr?subject=Dij'Innov Connect - Expression de besoins&body=Nom :%0D%0APrénom :%0D%0AFonction :%0D%0AMail :%0D%0ATel :%0D%0AMessage :"
                            class="text-green-dark"
                        >
                            <u>Je n'ai pas encore de besoins précis et souhaite être contacté</u>
                        </a>
                    </div>
                </form>
            </div>

            <router-link to="/" class="btn btn-primary mt-4 px-3 py-2 text-uppercase"> Retour à l'accueil </router-link>
        </div>
    </div>
</template>

<script>
import router from "../plugins/router";
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
const saveLadyjtechDepotProblematique = httpsCallable(functions, "saveLadyjtechDepotProblematique");
const empty_form = {
    company_name: "",
    company_domain: "",
    thematique: "",
    keywords: "",
    description: "",
    full_name: "",
    company_role: "",
    email: "",
    phone_number: "",
};

export default {
    created() {
        this.cleanData();
    },
    data() {
        return {
            isClosed: false,
            isLoading: false,
            hasError: false,
            form_data: {
                company_name: "DMP EVENTS",
                company_domain: "Medical",
                thematique: "AI dans les hopitaux",
                keywords: "ai, medecine",
                description: "ma description",
                full_name: "Damien Modin",
                company_role: "DG",
                email: "dm@test.fr",
                phone_number: "0102030405",
            },
        };
    },
    props: {},
    methods: {
        async saveData() {
            if (!this.isLoading) {
                this.isLoading = true;
                const form = document.querySelector(".needs-validation");
                form.classList.add("was-validated");
                if (form.checkValidity()) {
                    await saveLadyjtechDepotProblematique(this.form_data)
                        .then((result) => {
                            const data = result.data;
                            if (data) {
                                this.cleanData();
                                router.push({ name: "done-depot" });
                            } else {
                                this.$data.hasError = true;
                                form.classList.remove("was-validated");
                                window.scrollTo(0, 0);
                            }
                        })
                        .catch((error) => {
                            const code = error.code;
                            const message = error.message;
                            const details = error.details;
                            console.log(code, message, details);
                            this.$data.hasError = true;
                            form.classList.remove("was-validated");
                            window.scrollTo(0, 0);
                        });
                }

                this.isLoading = false;
            }
        },
        cleanData() {
            this.form_data = Object.assign({}, empty_form);
            const form = document.querySelector(".needs-validation");
            form.classList.remove("was-validated");
        },
    },
};
</script>

<style lang="scss" scoped>
$primary: #ab167e;
$secondary: #484846;
.loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: rgba($color: $secondary, $alpha: 0.5);
}
</style>
